import React, { useState } from "react";
import '../../App.css';
import ArticleCard from "../ArticleCard";
import MUICard2 from "../MUICard2";
import { Box } from "@mui/material";
import MUISingleEventCard from "../MUISingleEventCard";

function SingleEvent2() {

    const [heroSectionEventData] = useState({
        image: "images/Georgian-Music/2024-2025/gm_03-11-24.jpg",
        title: 'Pianist Michael Kim',
        subtitle: 'Michael Kim',
        date: 'Sunday 03 November 2024 3.00pm',
        location: 'Bethel Community Church, 128 St. Vincent St., Barrie',
        buttonText: 'Book Tickets',
        buttonLink: 'https://www.showpass.com/ishay-shaer-on-piano/'
    });

    const [eventData] = useState({
        program: `Michael Kim will take you on a mesmerizing journey through the history and evolution of the piano literature. Infused with interesting anecdotes chronicling pop culture and music practices of the time, this unique program travels through the concert halls to the courtyards and salons of Mozart and Brahms. It then transports you to the great concert stages of Chopin and as far as prestigious jazz halls of Gershwin.`,
        performers: `Pianist Michael Kim has brought audiences to their feet with his outstanding technique and refined artistry. A gifted performer, he has been praised by The Globe and Mail for his “consistently high level of professionalism and musicality.”

 

Mr. Kim is the recipient of a multitude of awards, including the grand-prize of the Canadian Music Competition and the CBC National Radio Competition for Young Performers.  He was also silver medallist at the Scottish International Competition in Glasgow and a prize winner in the Leeds and Ivo Pogorelich International Piano Competitions. An artistic scholar, Dr. Kim received his Master of Music and Doctor of Musical Arts from The Juilliard School, where he held the Vladimir Horowitz Piano Scholarship. Upon graduation, Michael quickly took a professorship at Lawrence University's Conservatory of Music in Appleton, WI. In 2015, Dr. Kim was appointed Director of the University of Minnesota School of Music. He served as Dean of Brandon University's School of Music, in Manitoba from 2008-2015.

 

Dr. Kim has toured extensively in North America, South America, the United Kingdom and Korea. As a concerto soloist, he has performed with the Boston and Cincinnati Pops, the Manitoba Chamber Orchestra, the symphonies of Toronto, Vancouver, Victoria, Winnipeg, Edmonton, Regina, Saskatoon, Milwaukee, Green Bay, Oklahoma City, Orchestra London, the Florida Orchestra, and the Calgary Philharmonic Orchestra, among others. Michael's touring history also includes highlights such as being invited to tour Peru with Orchestra Sinfonica de Trujillo and the UK with the Royal Scottish National Orchestra and the BBC Scottish Symphony. This season, he will perform with the Vancouver Island Symphony Orchestra.

 

As a chamber musician, Dr. Kim has performed at the festivals of Brevard, Aspen, Banff, Huntsville, and Heifetz (Annapolis, MD) to name a few. He also frequently collaborates with his wife, pianist Kyungran Kim, in a special four hand - one piano program. Dr. Kim was also the pianist for the highly successful production The Schumann Letters which has toured across the county.`,
        performerLink: 'https://www.andrewkwanartists.com/michael-kim.html',
        pdfPath: `pdf/2024-2025/gm_03-11-24.pdf`,
        ticketInfo: `Single tickets for this concert will become available about 30 days before the concert.  Live-streaming is no longer being offered.  Subscriptions are still available.Single tickets for this concert will become available about 30 days before the concert.  Live-streaming is no longer being offered.  Subscriptions are still available.`,
        performance: `J. S. Bach: Partita No. 2 in C minor 
        L. v. Beethoven: Six Bagatelles, Op. 126 
        C. Debussy: Images, book 1 
        R. Schumann: Fantasia in C, Op. 17`
    });
    

    const georgianMusic = [
        {
            title: 'Venuti Quartet',
            description: 'Indigo',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_15-12-24.jpg',
            link: '/SingleEvent3'
        },
        {
            title: 'Hall & Farley',
            description: 'A Nightingale Sang',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_02-02-25.jpg',
            link: '/SingleEvent4'
        },
        {
            title: 'Piano Duo',
            description: 'The World of Yesterday',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_13-04-25.jpg',
            link: '/SingleEvent5'
        }
    ];
    

    return (
        <Box> 
            <MUISingleEventCard
                image={heroSectionEventData.image}
                title={heroSectionEventData.title}
                subtitle={heroSectionEventData.subtitle}
                date={heroSectionEventData.date}
                location={heroSectionEventData.location}
                buttonText={heroSectionEventData.buttonText}
                buttonLink={heroSectionEventData.buttonLink} 
            />
            <ArticleCard 
                title={eventData.title}
                subtitle={eventData.subtitle}
                date={eventData.date}
                location={eventData.location}
                program={eventData.program}
                performers={eventData.performers}
                performerLink={eventData.performerLink}
                ticketInfo={eventData.ticketInfo}
                performance={eventData.performance}
                pdfPath={eventData.pdfPath}
            />
            <Box marginY='30px'>
                <MUICard2 title="2024/25 Georgian Music" events={georgianMusic} buttonLink="/GeorgianMusic" />
            </Box>
        </Box>
    );
}

export default SingleEvent2;
