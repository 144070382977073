// SingleEvent.js
import React, { useState } from "react";
import '../../App.css';
import ArticleCard from "../ArticleCard";
import MUICard2 from "../MUICard2";
import { Box } from "@mui/material";
import MUISingleEventCard from "../MUISingleEventCard";


function SingleEvent4() {

    const [heroSectionEventData] = useState({
        image: "images/Georgian-Music/2024-2025/gm_02-02-25.jpg",
        title: 'A Nightingale Sang',
        subtitle: 'Hall & Farley',
        date: 'Sunday 02 February 2025 3.00pm',
        location: 'Bethel Community Church, 128 St. Vincent St., Barrie',
        buttonText: 'Book Tickets',
        buttonLink: 'https://www.showpass.com/hall-and-farley/'
    });

    const [eventData] = useState({
        program: `Meredith Hall and Bernard Farley will delight with a series of songs and arias ranging from Mozart and Brahms, to Holst, to traditional Newfoundland folk songs.  For more information and for the words to the songs, please click on the tab below.  `,

        performers: `
Canadian soprano Meredith Hall delights audiences with her “lustrous sound and fluent legato” (San Francisco Chronicle) and “bravura musical performance matched by a riveting sense of the dramatic” (Boston Globe). Ms. Hall has performed the title roles of numerous operas. Ms. Hall has sung the oratorio masterworks of Bach, Handel, Schubert and Mozart.  A proud Newfoundlander, Ms. Hall's first love is folk music. She has created many projects which build on her Celtic roots. SACRUM MYSTERIUM, her Christmas concert and with Apollo's Fire (Cleveland) has received rave reviews and toured to sold out audiences across the United States. She also records and tours internationally with her husband, classical guitarist Bernard Farley.
Bernard Farley (Guitar): Praised for his "superb technique coupled with rare expressivity"  Bernard Farley has performed as soloist and chamber musician in concerts and festivals in Canada, the Caribbean, the United States and Europe. With his wife, soprano Meredith Hall, he has toured internationally.   They have recorded a CD of folksongs, arias, and lieder entitled My Fond Heart.  Bernard has been broadcast on Radio France, NPR and CBC. 
Please visit the performers’ website at the tab below for more information.
`,
        performerLink: 'https://www.deanartists.com/meredith-hall',
        ticketInfo: `Single tickets for this concert will become available about 30 days before the concert.  Live-streaming is no longer being offered.  Subscriptions are still available.`,
        pdfPath: `pdf/2024-2025/gm_02-02-25.pdf`,
        pdfPath2: `pdf/2024-2025/gm_02-02-25-lyrics.pdf`
    });
    

    const georgianMusic = [
        {
            title: 'Venuti Quartet',
            description: 'Indigo',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_15-12-24.jpg',
            link: '/SingleEvent3'
        },
        {
            title: 'Hall & Farley',
            description: 'A Nightingale Sang',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_02-02-25.jpg',
            link: '/SingleEvent4'
        },
        {
            title: 'Piano Duo',
            description: 'The World of Yesterday',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_13-04-25.jpg',
            link: '/SingleEvent5'
        }
    ];
    

    return (
        <Box> 
            <MUISingleEventCard
                image={heroSectionEventData.image}
                title={heroSectionEventData.title}
                subtitle={heroSectionEventData.subtitle}
                date={heroSectionEventData.date}
                location={heroSectionEventData.location}
                buttonText={heroSectionEventData.buttonText}
                buttonLink={heroSectionEventData.buttonLink} 
            />

            <ArticleCard 
                title={eventData.title}
                subtitle={eventData.subtitle}
                date={eventData.date}
                location={eventData.location}
                program={eventData.program}
                performers={eventData.performers}
                performerLink={eventData.performerLink}
                ticketInfo={eventData.ticketInfo}
                performance={eventData.performance}
                pdfPath={eventData.pdfPath}
                pdfPath2={eventData.pdfPath2} 
            />

            <Box marginY='30px'>
                <MUICard2 title="2024/25 Georgian Music" events={georgianMusic} buttonLink="/GeorgianMusic" />
            </Box>
        </Box>
    );
}

export default SingleEvent4;
