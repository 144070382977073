// SingleEvent.js
import React, { useState } from "react";
import '../../App.css';
import ArticleCard from "../ArticleCard";
import MUICard2 from "../MUICard2";
import { Box } from "@mui/material";
import MUISingleEventCard from "../MUISingleEventCard";

function SingleEvent11() {

    const [heroSectionEventData] = useState({
        image: "images/Barrie-Concerts/2024-2025/bc_26_04_25.jpg",
        title: 'Piano virtuosity at its finest',
        subtitle: 'Toronto Concert Orchestra',
        date: 'Saturday 26 April 2025 7.30pm',
        location: 'Hiway Church, 50 Anne Street North, Barrie',
        buttonText: 'Book Tickets',
        buttonLink: 'https://www.showpass.com/barrie-concerts-sat-series-2024-2025-bca/ '
    });

    const [eventData] = useState({
        program: `Toronto Concert Orchestra is thrilled to perform “Piano Virtuosity at its Finest”! This program includes two of the most beloved masterpieces of the classical/ romantic repertory, both by genius composer Ludwig van Beethoven. Pianist Younggun Kim will dazzle in the famous ‘Emperor’ piano concerto, followed by the stunning and heroic 7th Symphony. Conductors Sharon Lee and Marcus Scholtes will double as your hosts for the evening. An incredible event that will showcase the power and emotion of music.`,

performers: `Toronto Concert Orchestra, Sharon Lee and Marcus Scholtes, conductors. `,
performers2: `Younggun Kim, piano. `,
        performerLink: 'https://www.tcomusic.ca/',
        ticketInfo: `Purchase your season subscription early. 5 amazing upcoming concerts. Adults Live-streaming: Available at the time of your choosing for up to 30 days after the concert - $90. Adults In-person: - $130. Students: $25 either in-person or live-streamed. Single Tickets: These will be made available for each concert starting about 30 days before the event. Single tickets for this concert will become available about 30 days before the concert.  Live-streaming is no longer being offered.  Subscriptions are still available.  
This applies to each future concert listing.`,

    });

    const barrieConcert = [
        {
            title: 'Duo Turgeon',
            description: 'From Bruce with Love',
            imageUrl: 'images/Barrie-Concerts/2024-2025/bc_05-10-24.jpg',
            link: '/SingleEvent6'
        },
        {
            title: 'Dévah Quartet',
            description: 'Dévah Quartet',
            imageUrl: 'images/Barrie-Concerts/2024-2025/bc_30-11-24.jpg',
            link: '/SingleEvent7'
        },
        {
            title: 'True North Brass',
            description: 'Dancing Across the Globe',
            imageUrl: 'images/Barrie-Concerts/2024-2025/bc_18_01_25.jpg',
            link: '/SingleEvent8'
        },
    ];
    

    return (
        <Box> 
            <MUISingleEventCard
                image={heroSectionEventData.image}
                title={heroSectionEventData.title}
                subtitle={heroSectionEventData.subtitle}
                date={heroSectionEventData.date}
                location={heroSectionEventData.location}  
                buttonText={heroSectionEventData.buttonText}
                buttonLink={heroSectionEventData.buttonLink}
            />
            <ArticleCard 
                title={eventData.title}
                subtitle={eventData.subtitle}
                date={eventData.date}
                location={eventData.location}
                program={eventData.program}
                performers={eventData.performers}
                performers2={eventData.performers2}
                performerLink={eventData.performerLink}
                ticketInfo={eventData.ticketInfo}
            />
            <Box marginY='30px'>
                <MUICard2 title="2024/25 Barrie Concerts" events={barrieConcert} buttonLink="/BarrieConcert" />
            </Box>
        </Box>
    );
}

export default SingleEvent11;
