// ArticleCard.js
import React from 'react';
import { Card, CardContent, Typography, Box, Divider, Button } from '@mui/material';
import MUIButton from './MUIButton'; // Import the custom button

const ArticleCard = ({ program, program2, performers, performers2, ticketInfo, performerLink, pdfPath, pdfPath2 }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" bgcolor="#f5f5f5">
      <Card sx={{ width: '100%', bgcolor: '#e0e0e0', padding: '20px', position: 'relative' }}>
        <CardContent>
          {/* The Program Section */}
          <Box sx={{ mt: 2 }}>
            <Typography variant="h5">The Program</Typography>
            <Typography variant="body2">
              {program}
            </Typography>
            <Typography variant="body2">
              {program2}
            </Typography>
            {/* Add the PDF Download Button */}
            <Box mt={2}>
              <a href={pdfPath} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                <MUIButton variant="tertiary" size="medium">
                  Access Program Details
                </MUIButton>
              </a>
            </Box>
          </Box>
          
          {/* <Box marginTop="20px">
                <MUIButton
                    variant="tertiary"
                    size="medium"
                    href={pdfPath2} 
                    rel="noopener noreferrer" 
                    target="_blank" 
                >
                    View Song Lyrics
                </MUIButton>
            </Box> */}

          <Divider sx={{ my: 2, bgcolor: 'grey.500' }} />

          {/* The Performers Section */}
          <Box mt={2}>
            <Typography variant="h5">The Performers</Typography>
            <Typography variant="body2" mb={2}>
              {performers}
            </Typography>
            <Typography variant="body2">
              {performers2}
            </Typography>
            {/*Performer Link Button */}
            <Box mt={2}>
              <a href={performerLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                <MUIButton variant="tertiary" size="medium">
                  Visit Performer's Website
                </MUIButton>
              </a>
            </Box>


          </Box>
          <Divider sx={{ my: 2, bgcolor: 'grey.500' }} />

          {/* Ticket Information Section */}
          <Box mt={2}>
            <Typography variant="h5">Ticket Information</Typography>
            <Typography variant="body2">
              {ticketInfo}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ArticleCard;
