// SingleEvent.js
import React, { useState } from "react";
import '../../App.css';
import ArticleCard from "../ArticleCard";
import MUICard2 from "../MUICard2";
import { Box } from "@mui/material";
import MUISingleEventCard from "../MUISingleEventCard";

function SingleEvent8() {

    const [heroSectionEventData] = useState({
        image: "images/Barrie-Concerts/2024-2025/bc_18_01_25.jpg",
        title: 'True North Brass',
        subtitle: 'Dancing Across the Globe',
        date: 'Saturday 18 January 2025 7.30pm',
        location: 'Hiway Church, 50 Anne Street North, Barrie',
        buttonText: 'Book Tickets',
        buttonLink: 'https://www.showpass.com/true-north-brass-globe/'
    });

    const [eventData] = useState({
        program: `Dancing Around the Globe is a show True North Brass has put together that features rhythmic music from many countries. It takes our audience on a sweeping journey through Europe and the Americas with musical stops that keep our spirits buoyed and toes tapping.`,
        performers: `For over twenty years the True North Brass has been a prominent fixture on the landscape of brass chamber music. In 2018 the True North Brass reemerges onto the Canadian and international concert stage with new membership, a new sound and new repertoire, reestablishing itself as one of the world’s finest brass ensembles. The new True North Brass, representing some of Canada’s most prominent orchestral brass players, is carrying on the True North Brass tradition, equally at home in recital, in music education or collaborating with symphony orchestras.`,
        performerLink: 'https://truenorthbrass.com/',
        ticketInfo: `Single tickets for this concert will become available about 30 days before the concert.  Live-streaming is no longer being offered.  Subscriptions are still available.`,
        pdfPath: `pdf/2024-2025/bc_18_01_25.pdf`,

    });

    const barrieConcert = [
        {
            title: 'Duo Turgeon',
            description: 'From Bruce with Love',
            imageUrl: 'images/Barrie-Concerts/2024-2025/bc_05-10-24.jpg',
            link: '/SingleEvent6'
        },
        {
            title: 'Dévah Quartet',
            description: 'Dévah Quartet',
            imageUrl: 'images/Barrie-Concerts/2024-2025/bc_30-11-24.jpg',
            link: '/SingleEvent7'
        },
        {
            title: 'Ensemble Caprice',
            description: 'Vivaldi on Fire',
            imageUrl: 'images/Barrie-Concerts/2024-2025/bc_22_02_25.jpg',
            link: '/SingleEvent9'
        },
    ];
    

    return (
        <Box> 
            <MUISingleEventCard
                image={heroSectionEventData.image}
                title={heroSectionEventData.title}
                subtitle={heroSectionEventData.subtitle}
                date={heroSectionEventData.date}
                location={heroSectionEventData.location}
                buttonText={heroSectionEventData.buttonText}
                buttonLink={heroSectionEventData.buttonLink}
            />
            <ArticleCard 
                title={eventData.title}
                subtitle={eventData.subtitle}
                date={eventData.date}
                location={eventData.location}
                program={eventData.program}
                performers={eventData.performers}
                performerLink={eventData.performerLink}
                ticketInfo={eventData.ticketInfo}
                pdfPath={eventData.pdfPath}
            />
            <Box marginY='30px'>
                <MUICard2 title="2024/25 Barrie Concerts" events={barrieConcert} buttonLink="/BarrieConcert" />
            </Box>
        </Box>
    );
}

export default SingleEvent8;
