// SingleEvent.js
import React, { useState } from "react";
import '../../App.css';
import ArticleCard from "../ArticleCard";
import MUICard2 from "../MUICard2";
import { Box } from "@mui/material";
import MUISingleEventCard from "../MUISingleEventCard";

function SingleEvent9() {

    const [heroSectionEventData] = useState({
        image: "images/Barrie-Concerts/2024-2025/bc_22_02_25.jpg",
        title: 'Ensemble Caprice',
        subtitle: 'Vivaldi on Fire',
        date: 'Saturday 22 February 2025 7.30pm',
        location: 'Hiway Church, 50 Anne Street North, Barrie',
        buttonText: 'Book Tickets',
        buttonLink: 'https://www.showpass.com/ensemble-caprice/'
    });

    const [eventData] = useState({
        program: `Ensemble Vivant and soprano Janelle Lucyk perform works of Vivaldi and other anonymous composers of his era.`,
performers: `Janelle Lucyk: soprano.   Matthias Maute: recorder, baroque flute, conductor.   Sophie Larivière:  recorder, baroque flute. Lucie Ringuette:  baroque violon.   Karin Cuellar:  baroque violon.  `,
performers2: 'Pemi Paull:  viola.   Jean-Christophe Lizotte:  cello.   David Jacques:  baroque guitar.   Ziya Tabassian:  percussion.',
performerLink: 'https://www.ensemblecaprice.com/',
        ticketInfo: `Single tickets for this concert will become available about 30 days before the concert.  Live-streaming is no longer being offered.  Subscriptions are still available.`,
        pdfPath: `pdf/2024-2025/bc_22_02_25.pdf`,
    });

    const barrieConcert = [
        {
            title: 'Duo Turgeon',
            description: 'From Bruce with Love',
            imageUrl: 'images/Barrie-Concerts/2024-2025/bc_05-10-24.jpg',
            link: '/SingleEvent6'
        },
        {
            title: 'Dévah Quartet',
            description: 'Dévah Quartet',
            imageUrl: 'images/Barrie-Concerts/2024-2025/bc_30-11-24.jpg',
            link: '/SingleEvent7'
        },
        {
            title: 'True North Brass',
            description: 'Dancing Across the Globe',
            imageUrl: 'images/Barrie-Concerts/2024-2025/bc_18_01_25.jpg',
            link: '/SingleEvent8'
        },
    ];
    

    return (
        <Box> 
            <MUISingleEventCard
                image={heroSectionEventData.image}
                title={heroSectionEventData.title}
                subtitle={heroSectionEventData.subtitle}
                date={heroSectionEventData.date}
                location={heroSectionEventData.location}  
                buttonText={heroSectionEventData.buttonText}
                buttonLink={heroSectionEventData.buttonLink}
            />
            <ArticleCard 
                title={eventData.title}
                subtitle={eventData.subtitle}
                date={eventData.date}
                location={eventData.location}
                program={eventData.program}
                performers={eventData.performers}
                performers2={eventData.performers2}
                performerLink={eventData.performerLink}
                ticketInfo={eventData.ticketInfo}
                pdfPath={eventData.pdfPath}
            />
            <Box marginY='30px'>
                <MUICard2 title="2024/25 Barrie Concerts" events={barrieConcert} buttonLink="/BarrieConcert" />
            </Box>
        </Box>
    );
}

export default SingleEvent9;
