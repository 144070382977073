// SingleEvent.js
import React, { useState } from "react";
import '../../App.css';
import ArticleCard from "../ArticleCard";
import MUICard2 from "../MUICard2";
import { Box } from "@mui/material";
import MUISingleEventCard from "../MUISingleEventCard";

function SingleEvent7() {

    const [heroSectionEventData] = useState({
        image: "images/Barrie-Concerts/2024-2025/bc_30-11-24.jpg",
        title: 'Dévah Quartet',
        subtitle: 'Dévah Quartet',
        date: 'Saturday 30 November 2024 7.30pm',
        location: 'Hiway Church, 50 Anne Street North, Barrie',
        buttonText: 'Book Tickets',
        buttonLink: 'https://www.showpass.com/the-devah-quartet/'
    });

    const [eventData] = useState({
        program: `Dévah will play music ranging from works by Haydn and Shostakovich to others by Taylor Swift and Guns N’ Roses. "Like a refreshing version of Apocalyptica." – Nick Lee, Best of Toronto. "…create then crush preconceived notions." – Dakota Arsenault, Live Music TO.`,
        performers: `Dévah is a multi-genre string quartet that explores all aspects of the string quartet's possibilities. Dévah recently released their third album, Prometheus, following their second album, 2112, which pays tribute to Rush. Both albums have gained recognition in various prog rock journals, with 2112 highlighted in UK’s Prog magazine, leading to their performance at a tribute for Neil Peart. `,
        performerLink: 'https://www.devahquartet.com/',
        ticketInfo: `Single tickets for this concert will become available about 30 days before the concert.  Live-streaming is no longer being offered.  Subscriptions are still available.`,
        pdfPath: `pdf/2024-2025/bc_30-11-24.pdf`,
    });

    const barrieConcert = [
        {
            title: 'True North Brass',
            description: 'Dancing Across the Globe',
            imageUrl: 'images/Barrie-Concerts/2024-2025/bc_18_01_25.jpg',
            link: '/SingleEvent8'
        },
        {
            title: 'Ensemble Caprice',
            description: 'Vivaldi on Fire',
            imageUrl: 'images/Barrie-Concerts/2024-2025/bc_22_02_25.jpg',
            link: '/SingleEvent9'
        },
        {
            title: 'Francine Kay',
            description: 'Things Lived and Dreamt',
            imageUrl: 'images/Barrie-Concerts/2024-2025/bc_29_03_25.jpg',
            link: '/SingleEvent10'
        }
    ];
    

    return (
        <Box> 
            <MUISingleEventCard
                image={heroSectionEventData.image}
                title={heroSectionEventData.title}
                subtitle={heroSectionEventData.subtitle}
                date={heroSectionEventData.date}
                location={heroSectionEventData.location}
                buttonText={heroSectionEventData.buttonText}
                buttonLink={heroSectionEventData.buttonLink}
            />
            <ArticleCard 
                title={eventData.title}
                subtitle={eventData.subtitle}
                date={eventData.date}
                location={eventData.location}
                program={eventData.program}
                performers={eventData.performers}
                performerLink={eventData.performerLink}
                ticketInfo={eventData.ticketInfo}
                pdfPath={eventData.pdfPath}
            />
            <Box marginY='30px'>
                <MUICard2 title="2024/25 Barrie Concerts" events={barrieConcert} buttonLink="/BarrieConcert" />
            </Box>
        </Box>
    );
}

export default SingleEvent7;
